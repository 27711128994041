import React from 'react';

import "./impact.css";
import Reach from './reach';
import Testmonials from './testmonials';

export default function Content() {
  return (
    <div  className='impact_content'>
       
        <Reach/>
        <Testmonials/>
    </div>
  )
}
