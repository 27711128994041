import React from 'react'

import NavBar from '../../components/navbar/navbar'


export default function Donate() {
  return (
    <div>
       <NavBar/>
     
    </div>
  )
}
