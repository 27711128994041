import React from 'react';
import "./aboutus.css";
export default function Vission() {
  return (
    <div className='vission'>
        <div style={{fontSize:"32px",fontWeight:"bold",color:'teal'}}>Our Vission</div>
        <div className='vission-content'>
          A community empored to solve its challenges
        </div>
    </div>
  )
}
