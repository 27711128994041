export const SET_AUTH_PAGE = "SET_AUTH_PAGE";
export const SET_DASH_TAB = "SET_DASH_TAB";
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_SUCCESS = "GET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const SERVER_ERROR = 'SERVER_ERROR';


export const LOADING_AREAS = "LOADING_AREAS";
export const LOAD_AREAS = "LOAD_AREAS";
export const LOADING_VILLAGES = "LOADING_VILLAGES";
export const LOAD_VILLAGES = "LOAD_VILLAGES";
export const LOADING_VILLAGES_FAIL = "LOADING_VILLAGES_FAIL";
export const LOADING_AREAS_FAIL = "LOADING_AREAS_FAIL";
export const ADDING_VILLAGE = "ADDING_VILLAGE";
export const ADDING_VILLAGE_FAIL = "ADDING_VILLAGE_FAIL";
export const ADDING_VILLAGE_SUCCESS = "ADDING_VILLAGE_SUCCESS";
export const ADDING_AREA = "ADDING_AREA";
export const ADDING_AREA_FAIL = "ADDING_AREA_FAIL";
export const ADDING_AREA_SUCCESS = "ADDING_AREA_SUCCESS";

export const REGISTERING_USER = "REGISTERING_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const LOGGING_USER = "LOGGING_USER";
export const LOGGING_USER_SUCCESS = "LOGGING_USER_SUCCESS";
export const LOGGING_USER_ERROR = "LOGGING_USER_ERROR";
export const REQUESTING_CODE = "REQUESTING_CODE";
export const REQUESTING_CODE_SUCCESS = "REQUESTING_CODE_SUCCESS";
export const REQUESTING_CODE_ERROR = "REQUESTING_CODE_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const LOADING_USER = "LOADING_USER";
export const USER_LOADING_SUCCESS = "USER_LOADING_SUCCESS";
export const USER_LOADING_ERROR = "USER_LOADING_ERROR";
export const UPDATING_USER = "UPDATING_USER";
export const UPDATING_USER_SUCCESS = "UPDATING_USER_SUCCESS";
export const UPDATING_USER_ERROR = "UPDATING_USER_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOADING_USERS = "LOADING_USERS";
export const USERS_LOADING_SUCCESS = "USERS_LOADING_SUCCESS";
export const USERS_LOADING_ERROR = "USERS_LOADING_ERROR";
export const SET_ADMIN_TAB = "SET_ADMIN_TAB";
export const ADMIN_UPDATING_USER = "ADMIN_UPDATING_USER";
export const ADMIN_UPDATING_USER_SUCCESS = "ADMIN_UPDATING_USER_SUCCESS";
export const ADMIN_UPDATING_USER_ERROR = "ADMIN_UPDATING_USER_ERROR";

export const LOADING_AREA_REPS = "LOADING_AREA_REPS";
export const AREA_REPS_LOADING_SUCCESS = "AREA_REPS_LOADING_SUCCESS";
export const AREA_REPS_LOADING_ERROR = "AREA_REPS_LOADING_ERROR";
export const ADDING_BOARD_MEMBER = "ADDING_BOARD_MEMBER";
export const BOARD_MEMBER_ADDED_SUCCESS = "BOARD_MEMBER_ADDED_SUCCESS";
export const BOARD_MEMBER_ADD_FAIL = "BOARD_MEMBER_ADD_FAIL";
export const REMOVING_BOARD_MEMBER = "REMOVING_BOARD_MEMBER";
export const REMOVING_BOARD_MEMBER_SUCCESS = "REMOVING_BOARD_MEMBER_SUCCESS";
export const REMOVING_BOARD_MEMBER_FAIL = "REMOVING_BOARD_MEMBER_FAIL";
export const UPDATING_BOARD_MEMBER = "UPDATING_BOARD_MEMBER";
export const UPDATING_BOARD_MEMBER_SUCCESS = "UPDATING_BOARD_MEMBER_SUCCESS";
export const UPDATING_BOARD_MEMBER_FAIL = "UPDATING_BOARD_MEMBER_FAIL";
export const LOADING_BOARD_MEMBERS = "LOADING_BOARD_MEMBERS";
export const BOARD_MEMBERS_LOADING_SUCCESS = "BOARD_MEMBERS_LOADING_SUCCESS";
export const BOARD_MEMBERS_LOADING_ERROR = "BOARD_MEMBERS_LOADING_ERROR";

export const LOADING_REPORTS = "LOADING_REPORTS";
export const REPORTS_LOADING_SUCCESS = "REPORTS_LOADING_SUCCESS";
export const REPORTS_LOADING_ERROR = "REPORTS_LOADING_ERROR";

export const LOADING_PROGRAMS = "LOADING_PROGRAMS";
export const LOADING_PROGRAMS_SUCCESS = "LOADING_PROGRAMS_SUCCESS";
export const LOADING_PROGRAMS_ERROR = "LOADING_PROGRAMS_ERROR";