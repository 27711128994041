import React from 'react';
import "./main.css";

export default function Declaration() {
  return (
    <div className='declaration'>
        <div >Karinga Massive Development Organization  works
           to improve quality of life of our community.

        </div>
       
        
    </div>
  )
}
